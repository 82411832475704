require("./src/fonts/Submittable_Font_Kit.css");
require("./src/styles/reset.css");

const isClient = typeof window === "object";

const getCookie = (name) => {
	if (isClient) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
};

const readCookie = (query) => {
	// first test if url parameter starts with '?'
	if (query[0] === "?") {
		query = query.slice(1, query.length);
	}
	let params = {};
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i += 1) {
		const pair = vars[i].split("=");
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return query ? params : null;
};

exports.onClientEntry = () => {
	if (getCookie("redirect_www_to") && window?.gtag) {
		const cookieValue = readCookie(getCookie("redirect_www_to"));
		const isOrgMember = cookieValue.user_type === "org member";
		const isSubmittter = cookieValue.user_type === "submitter";
		console.log("redirect cookie read", cookieValue, "is org?", isOrgMember);

		if (isOrgMember) {
			window.gtag("event", "Org member landed on site with manager cookie", {
				event_category: "User",
				event_label: `id: ${cookieValue.userId}`,
			});
		}
		if (isSubmittter) {
			window.gtag("event", "Submitter landed on site with manager cookie", {
				event_category: "User",
				event_label: `id: ${cookieValue.userId}`,
			});
		}
	}
};

exports.onInitialClientRender = () => {
	let isSubmitter = false;
	if (isClient && document.cookie.indexOf("ref_orgsubmit=") >= 0) {
		isSubmitter = true;
	} else if (getCookie("redirect_www_to")) {
		const cookieValue = readCookie(getCookie("redirect_www_to"));
		isSubmitter = cookieValue.user_type === "submitter";
	}

	if (isClient && isSubmitter) {
		if (typeof Intercom !== "undefined") {
			console.log("hiding intercom launcher");
			window.Intercom("shutdown");
		}
	}

	// Exclude Intercom from certain paths
	if (
		isClient &&
		(location.pathname === "/help/submitter/" ||
			location.pathname === "/help/mnfrontlinepay_sp/" ||
			location.pathname === "/help/mnfrontlinepay_hm/" ||
			location.pathname === "/help/mnfrontlinepay_so/" ||
			location.pathname === "/help/mnfrontlinepay/")
	) {
		if (typeof Intercom !== "undefined") {
			console.log("hiding intercom launcher");
			window.Intercom("shutdown");
		}
	}
};

// Always start at the top of the page on a route change.
// Fixes Gatsby bug. See https://github.com/gatsbyjs/gatsby/pull/3483
exports.onRouteUpdate = () => {
	if (typeof window !== `undefined`) {
		window.scrollTo(0, 0); //scroll to top
	}
};

exports.shouldUpdateScroll = (args) => {
	return false;
};
